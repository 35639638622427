import React from "react";
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'


export const LoginComponent = () => {

    const history = useHistory();
    const { user, isAuthenticated, loginWithRedirect } = useAuth0();
    const { data } = useVisitorData({ linkedId: user?.sub });

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/');
        } else if (data) {
            return loginWithRedirect({
                authorizationParams: {
                    visitorId: `${data?.visitorId}`,
                    requestId: `${data?.requestId}`
                },
            });
        }

    }, [data])


    return (
        <div className="App">

            <header className="App-header">
                <h1 className="App-title">Redirecting to login...</h1>
            </header>
        </div>
    );
};

export default LoginComponent;