import React, { Fragment, useState, useEffect } from "react";
import VisitorDataPresenter from '../components/VisitorDataPresenter.tsx'
import SmartSignalsPresenter from '../components/SmartSignalsPresenter.tsx'
import SmartSignalsTable from '../components/SmartSignalsTable.tsx'
import { useAuth0 } from "@auth0/auth0-react";
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { getConfig } from "../config";

import Hero from "../components/Hero";
import { Container } from "reactstrap";

const { apiBaseUrl } = getConfig();

export const Home = () => {

  const { user } = useAuth0();
  const [signals, setSignals] = useState(null)
  const { data, isLoading, error } = useVisitorData({ linkedId: user?.sub, extendedResult: true });

  const getSmartSingals = (requestId) => {
    fetch(`${apiBaseUrl}/event/${requestId}`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setSignals(data.products)
        console.log(data.products)
      })
  }

  useEffect(() => {
    if (data?.visitorId) {
      getSmartSingals(data.requestId);
    }
  }, [data])


  return (
    <Fragment>
      <Hero />
      <Container>
        <VisitorDataComponent />
        <SmartSignalsTableComponent />
        <SmartSignalsComponent />
      </Container>
    </Fragment>

  )

  function VisitorDataComponent() {

    //console.log("signals are:" + JSON.stringify(signals));
    return <VisitorDataPresenter data={data} isLoading={isLoading} error={error} />
  }

  function SmartSignalsComponent() {
    return <SmartSignalsPresenter data={data} signals={signals} />
  }

  function SmartSignalsTableComponent() {
    return <SmartSignalsTable data={signals} />
  }

};

export default Home;


