import React from "react";

import logo from "../assets/fp.svg";

const Hero = () => (
  <div className="text-center hero" style={{ maxWidth: '800px' }}>
    <img className="app-logo" src={logo} alt="React logo" height="100px" />
    <h1 className="mb-4">Fingerprint Demo App</h1>

    <p className="lead1" style={{ fontSize: '1.45rem', fontWeight: '500', marginBottom: '50px' }}>
      Welcome to Demo site - New customers <a href='/sign-up'>sign up here for free promo offer  </a>
    </p>
  </div>
);

export default Hero;
