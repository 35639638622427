import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useState } from 'react';
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { getConfig } from "../config";
import { Alert, Button, Spinner } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

const SignupComponent = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setloading] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState('');

  const { apiBaseUrl } = getConfig();

  const { data } = useVisitorData();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "name") {
      setName(value);
    } else {
      setEmail(value);
    }
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    try {
      const response = await fetch(`${apiBaseUrl}/signup`, {
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "name": name,
          "email": email,
          "password": '',
          "fingerprint": `${data.visitorId} ${data.requestId}`,
        }),
        method: 'POST',
      });

      const responseData = await response.json();
      if (response?.ok) {
        setAlertVariant('success')
        setShowAlert(true);
        setAlert("User Created: " + responseData.email);
      } else {
        console.log(responseData);
        console.log(response);
        setShowAlert(true);
        setAlertVariant('danger')
        setAlert(responseData.message);
      }
    } catch (err) {
      console.log(err);

    }
    setloading(false);
  };

  return (
    <Container className="signup-form">
      <Alert show={showAlert} variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>
        <p name='alert-text'>{alert}</p>
      </Alert>
      <h2>Sign up for a free promo offer</h2>
      <form name="signup-form1" onSubmit={handleSubmit}>
        <title>Sign up for a £10 free bet</title>
        <div class="row">
          <div class="col-space"></div>
          <div class="col-label">
            <label for="fname">Email:</label>
          </div>
          <div class="col-input">
            <input
              type="email"
              name="email"
              value={email || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div class="row">
          <div class="col-space"></div>
          <div class="col-label">
            <label>Password:</label>
          </div>
          <div class="col-input">
            <input
              type="password"
              name="password"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-space"></div>
          <div class="col-label">
            <label>Full Name: </label>
          </div>
          <div class="col-input">
            <input
              type="text"
              name="name"
              value={name || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div class="sign-up-submit">
          <Button type="submit" variant="success" value="Submit" name="submit-form" id="73833hshs"
            style={{
              borderRadius: '10px', borderColor: '#ff5e24', backgroundColor: '#ff5e24', color: '#fff',
              fontWeight: 'bold'
            }}>
            {
              loading ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> : <></>}
            Submit </Button>
        </div>
      </form>
    </Container>
  );
};

export default SignupComponent;
