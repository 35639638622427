import React from 'react'
import { VisitorData, ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro-spa'
import dateFormat from 'dateformat';

function VisitorDataPresenter({ data, isLoading, error }: { data?: ExtendedGetResult; isLoading?: boolean; error?: Error }) {
  if (error) {
    return <p>An error occurred: {error.message}</p>
  }

  return (
    <div className='visitor-data'>
      <h4 className="MuiTypography-root MuiTypography-h2 home-headlines">Visitor ID:
        <div className="MuiBox-root css-1kbb6u6">
          {data ? ' ' + data.visitorId : ' not established yet'}
        </div>
      </h4>
      <h4 className="MuiTypography-root MuiTypography-h2 home-headlines">First Seen:
        <div className="MuiBox-root css-1kbb6u6">
          {data ? ' ' + dateFormat(data?.firstSeenAt.subscription, "dd-mm-yyyy HH:MM") : ' not established yet'}
        </div>
      </h4>
    </div>
  )
}

export default VisitorDataPresenter
