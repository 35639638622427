import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Box, Typography } from '@mui/material';
import dateFormat from 'dateformat';

function SmartSignalsTable({ data }) {

    if (!data) {
        return <p>An error occurred</p>
    }

    const RED = "#FFCDD2";
    const GREEN = "#F1F8E9";
    const GREY = "#ECEFF1";

    return (
        <div className='visitor-data'>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        sm: 'minmax(0, 500px)',
                        lg: 'repeat(3, minmax(0, 400px))',
                    },
                    justifyContent: 'center',
                    gap: 3,
                }}
            >

                <Box>
                    <Typography variant="h5" className="signals-header">Identification Result</Typography>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="simple table">
                            <TableBody>
                                <TableRow key='1' >
                                    <TableCell component="th" scope="row" width="50%">
                                        Visitor Id
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: GREEN }} align="left">{data.identification.data.visitorId}</TableCell>
                                </TableRow>
                                <TableRow key='2'>
                                    <TableCell component="th" scope="row">
                                        Last Seen
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: GREY }} align="left">
                                        {dateFormat(data.identification.data.lastSeenAt.subscription, "dd-mm-yyyy HH:MM")}</TableCell>
                                </TableRow>
                                <TableRow key='3'>
                                    <TableCell component="th" scope="row">
                                        IP Address
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: GREY }} align="left">{data.identification.data.ip}</TableCell>
                                </TableRow>
                                <TableRow key='4'>
                                    <TableCell component="th" scope="row">
                                        Operating System
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: GREY }} align="left">
                                        {data.identification.data.browserDetails.os + ' ' + data.identification.data.browserDetails.osVersion}  </TableCell>
                                </TableRow>
                                <TableRow key='5'>
                                    <TableCell component="th" scope="row">
                                        Browser
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: GREY }} align="left">
                                        {data.identification.data.browserDetails.browserName + ' ' + data.identification.data.browserDetails.browserFullVersion}  </TableCell>
                                </TableRow>
                                <TableRow key='6'>
                                    <TableCell component="th" scope="row">
                                        Confidence Score
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: GREY }} align="left">
                                        {data.identification.data.confidence.score}  </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box>
                    <Typography variant="h5" className="signals-header">Smart signals</Typography>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="simple table">
                            <TableBody>
                                <TableRow key='1' >
                                    <TableCell component="th" scope="row">
                                        Geo Location
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: GREY }} align="left">
                                        {data.identification.data.ipLocation.city.name + ', '
                                            + data.identification.data.ipLocation.country.name}</TableCell>
                                </TableRow>
                                <TableRow key='2' >
                                    <TableCell component="th" scope="row">
                                        Incognito Mode
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: data.identification.data.incognito === true ? RED : GREEN }} align="left">
                                        {data.identification.data.incognito === true ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow key='3' >
                                    <TableCell component="th" scope="row">
                                        Bot
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: data.botd.data.bot.result === true ? RED : GREEN }} align="left">
                                        {data.botd.data.bot.result === true ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow key='4' >
                                    <TableCell component="th" scope="row">
                                        VPN
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: data.vpn.data.result === true ? RED : GREEN }} align="left">
                                        {data.vpn.data.result === true ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow key='5' >
                                    <TableCell component="th" scope="row">
                                        Browser Tampering
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: data.tampering.data.result === true ? RED : GREEN }} align="left">
                                        {data.tampering.data.result === true ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow key='6' >
                                    <TableCell component="th" scope="row">
                                        Virtual Machine
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: data.virtualMachine.data.result === true ? RED : GREEN }} align="left">
                                        {data.virtualMachine.data.result === true ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow key='7' >
                                    <TableCell component="th" scope="row">
                                        Privacy Settings
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: data.privacySettings.data.result === true ? RED : GREEN }} align="left">
                                        {data.privacySettings.data.result === true ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box>
                    <Typography variant="h5" className="signals-header"> <br></br></Typography>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="simple table">
                            <TableBody>
                                <TableRow key='8' >
                                    <TableCell component="th" scope="row">
                                        IP Blocklist
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: data.ipBlocklist.data.result === true ? RED : GREEN }} align="left">
                                        {data.ipBlocklist.data.result === true ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow key='9' >
                                    <TableCell component="th" scope="row">
                                        Proxy
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: data.proxy.data.result === true ? RED : GREEN }} align="left">
                                        {data.proxy.data.result === true ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow key='10' >
                                    <TableCell component="th" scope="row">
                                        Tor Network
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: data.tor.data.result === true ? RED : GREEN }} align="left">
                                        {data.tor.data.result === true ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow key='11' >
                                    <TableCell component="th" scope="row">
                                        High Activity Device
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: data.highActivity.data.result === true ? RED : GREEN }} align="left">
                                        {data.highActivity.data.result === true ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow key='12' >
                                    <TableCell component="th" scope="row">
                                        Developer Tools
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: data.developerTools.data.result === true ? RED : GREEN }} align="left">
                                        {data.developerTools.data.result === true ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow key='13' >
                                    <TableCell component="th" scope="row">
                                        Remote Control Tools
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: data.remoteControl.data.result === true ? RED : GREEN }} align="left">
                                        {data.remoteControl.data.result === true ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow key='15' >
                                    <TableCell component="th" scope="row">
                                        Suspect Score
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: data.suspectScore.data.result > 9 ? RED : GREEN }} align="left">
                                        {data.suspectScore.data.result}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </div>
    )
}

export default SmartSignalsTable