import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import { FpjsProvider, CacheLocation } from "@fingerprintjs/fingerprintjs-pro-react";
import history from "./utils/history";
import { getConfig } from "./config";


const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  useRefreshTokens: true,
  cacheLocation: "localstorage",
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

ReactDOM.render(
  <FpjsProvider
    cacheLocation={CacheLocation.NoCache}
    loadOptions={{
      apiKey: config.fp_public_api_key,
      endpoint: ["https://metrics.hughesdemo.com", FpjsProvider.defaultEndpoint],
      region: "eu",
    }} >
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>,
  </FpjsProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
