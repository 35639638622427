import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
// @ts-ignore
import CodeSnippet from './CodeSnippet.tsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container, Row, Col } from "reactstrap";

function SmartSignalsPresenter({ data, signals }) {

  if (!data) {
    return <p>An error occurred</p>
  }

  return (
    <div className='visitor-data'>
      <Box
        sx={{ display: 'grid', gridTemplateColumns: { xs: 'minmax(0,1fr)', lg: 'repeat(2, minmax(0, 1fr))' }, gap: 3 }}
      >
        <Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Javascript Agent Response:</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CodeSnippet language="json">
                {JSON.stringify(data, null, 2)}
              </CodeSnippet>

            </AccordionDetails>
          </Accordion>
        </Box>
        <Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Server API Event Response:</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CodeSnippet language="json">
                {JSON.stringify(signals, null, 2)}
              </CodeSnippet>

            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </div>
  )
}

export default SmartSignalsPresenter
