import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/fp.svg";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);
  const { data } = useVisitorData();

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });

  async function loginWithFingerprint() {
    if (data) {
      return loginWithRedirect({
        authorizationParams: {
          visitorId: `${data?.visitorId}`,
          requestId: `${data?.requestId}`
        },
      });
    }
    return console.log(data);
  }


  return (
    <div className="nav-container" style={{ backgroundColor: '#0D3547' }}>
      <Navbar color="#0D3547" light expand="md">
        <Container>
          <NavbarBrand>
            <a href="/"><img className="mb-3 app-logo" src={logo} width="140" style={{ borderRadius: '10px', marginTop: '20px' }} alt="betfred logo" /> </a>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Nav className="d-none d-md-block" navbar>
            {!isAuthenticated && (
              <NavItem>
                <a href="/sign-up"> <Button
                  id="qsLoginBtn"
                  color="primary"
                  style={{ backgroundColor: '#fff', borderColor: '#ff5e24', color: '#ff5e24', fontWeight: 'bold' }}
                  className="btn-margin"
                >
                  Sign Up
                </Button></a>
                <Button
                  id="qsFingerprintLoginBtn"
                  color="primary"
                  style={{ backgroundColor: '#ff5e24', borderColor: '#ff5e24', color: '#fff', fontWeight: 'bold' }}
                  className="btn-margin"
                  onClick={() => loginWithFingerprint()}
                >
                  Log in
                </Button>
              </NavItem>
            )}
            {isAuthenticated && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret id="profileDropDown">
                  <img
                    src={user.picture}
                    alt="Profile"
                    className="nav-user-profile rounded-circle"
                    width="50"
                  />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>{user.name}</DropdownItem>
                  <DropdownItem
                    tag={RouterNavLink}
                    to="/profile"
                    className="dropdown-profile"
                    activeClassName="router-link-exact-active"
                  >
                    <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                  </DropdownItem>
                  <DropdownItem
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                    out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </Nav>

        </Container>
      </Navbar>
    </div >
  );
};

export default NavBar;
