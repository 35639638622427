import React from "react";
import logo from "../assets/fp.svg";

const Footer = () => (
  <footer className="text-center" style={{ backgroundColor: '#eff1f5' }}>
    <div className="logo1" style={{ backgroundColor: '#eff1f5 !important' }}>

      <img className="mb-3 app-logo" src={logo} alt="FP logo" width="120" />
    </div>
  </footer>
);

export default Footer;
