import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useState, useEffect } from 'react';
import dateFormat from 'dateformat';
import SmartSignalsPresenter from '../components/SmartSignalsPresenter.tsx'
import CodeSnippet from '../components/CodeSnippet.tsx'
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Modal, Card, ListGroup } from 'react-bootstrap';

export const ProfileComponent = () => {
  const { user } = useAuth0();
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [visits, setVisits] = useState(null);
  const [devices, setDevices] = useState([]);

  const { apiBaseUrl } = getConfig();
  const { data } = useVisitorData({ linkedId: user?.sub });
  const [show, setShow] = useState(false);

  const styles = {
    card: {
      padding: '1rem',
      width: '18rem',
      marginRight: '20px',
      marginBottom: '20px',
    },
    cardImage: {
      objectFit: 'cover',
      borderRadius: 55,
      height: '40%',
    }
  }

  const getVisitorHistory = (visitorId) => {
    fetch(`${apiBaseUrl}/visits/${visitorId}`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setVisits(data.visits)
        console.log(data.visits)
      })
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function getDeviceInfo(passedValue) {
    const reponse = await fetch(`${apiBaseUrl}/deviceInfo/${passedValue}`);
    const json = await reponse.json();
    return json;
  }

  const alertClicked = async (e) => {
    console.log(e.target.value);
    let device1 = await getDeviceInfo(e.target.value);
    setDeviceInfo(device1);
    console.log(JSON.stringify(device1));
    handleShow();
  };

  useEffect(() => {
    if (data?.visitorId) {
      getVisitorHistory(data.visitorId);
      if (user) {
        var values = user[`https://localhost.com/visitorIds`];
        console.log(values);
        setDevices(values)
      }
    }
  }, [data])

  return (
    <Container className="profile mb-5">
      <Row className="align-items-center profile-header mb-5 text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      <Row>
        <Card className="profile-devices">
          <h5> Trusted Device IDs:</h5>
          <Card style={{ width: '18rem' }}>
            <ListGroup variant="flush">
              {devices.map(item => {
                return <ListGroup.Item action onClick={alertClicked} value={item}>{item}</ListGroup.Item>
              })}
            </ListGroup>
          </Card>
        </Card>
      </Row>
      <Row className="profile-row">
        <h5>Identity Provider Token Response</h5>
        <Accordion style={{ marginTop: '10px', width: '100%', marginBottom: '20px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>ID Token Contents:</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CodeSnippet language="json">
              {JSON.stringify(user, null, 2)}
            </CodeSnippet>

          </AccordionDetails>
        </Accordion>
      </Row>
      <Row className="profile-row">
        <h5>Fingerprint Response</h5></Row>
      <Row>
        <h5 className="profile-heads">Linked Id:
          <div className="MuiBox-root profile-sub-heads">
            {visits ? ' ' + visits[0].linkedId : ' not set'}
          </div>
        </h5>
      </Row>
      <Row>
        <h5 className="profile-heads">Device First Seen:
          <div className="MuiBox-root profile-sub-heads">
            {visits ? ' ' + dateFormat(visits[0].firstSeenAt.subscription, "dd-mm-yyyy HH:MM") : ''}
          </div>

        </h5>
      </Row>
      <Row>
        <Accordion style={{ marginTop: '20px', width: '100%' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Last {visits?.length} visits:</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CodeSnippet language="json">
              {JSON.stringify(visits, null, 2)}
            </CodeSnippet>

          </AccordionDetails>
        </Accordion>
      </Row>

      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Visitor ID: {deviceInfo?.visitorId}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><b>Device: </b> {deviceInfo?.visits[0].browserDetails.device === "Other" ? 'Browser' : deviceInfo?.visits[0].browserDetails.device}</p>
            <p> <b>Browser Details: </b> {deviceInfo?.visits[0].browserDetails.browserName} { }
              {deviceInfo?.visits[0].browserDetails.browserFullVersion}</p>
            <p> <b>Operating System: </b> {deviceInfo?.visits[0].browserDetails.os}
              { } {deviceInfo?.visits[0].browserDetails.osVersion}</p>
            <p> <b>Last geo-location: </b> {deviceInfo?.visits[0].ipLocation.city.name + ', '
              + deviceInfo?.visits[0].ipLocation.country.name}</p>
            <p> <b>Device first seen at: </b>  {dateFormat(deviceInfo?.visits[0].firstSeenAt.subscription,
              "dd-mm-yyyy HH:MM")}</p>
            <p> <b>Device last seen at: </b>  {dateFormat(deviceInfo?.visits[0].lastSeenAt.subscription,
              "dd-mm-yyyy HH:MM")}</p>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

    </Container >
  );
};

export default ProfileComponent;

